import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Provider, ErrorBoundary } from '@rollbar/react';

import './index.css';

import { ApiProvider } from './shared/ApiContext';
import Toast from './shared/Toast';

import * as serviceWorker from './serviceWorker';
import Screens from './screens';

import brandTheme from './style/themes/muiCustomGlobalTheme';

const { REACT_APP_BRAND } = process.env;

switch (REACT_APP_BRAND) {
  case 'candy':
    import('./style/static/candy/index.css');
    import('./style/static/promoCard.css');
    break;
  case 'hoover':
    import('./style/static/hoover/index.css');
    import('./style/static/promoCard.css');
    break;
  case 'haier':
    import('./style/static/haier/index.scss');
    break;
  default:
    break;
}

const rollbarConfig = {
  accessToken: 'feed445908ed43d6abbce2aa310d5543',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: 'production',
  },
};

const App = () => (
  <Router>
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <MuiThemeProvider theme={brandTheme}>
          <Toast>
            {(props) => (
              <ApiProvider {...props}>
                <Screens />
              </ApiProvider>
            )}
          </Toast>
        </MuiThemeProvider>
      </ErrorBoundary>
    </Provider>
  </Router>
);

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
