import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

const Form = ({
  values: { password, passwordRepeat },
  errors,
  handleChange,
  handleSubmit,
  submitCount,
  isSubmitting,
}) => (
  <form onSubmit={handleSubmit}>
    <FormControl margin="normal" fullWidth>
      <TextField
        name="password"
        type="password"
        autoComplete="off"
        error={!!submitCount && Boolean(errors.password)}
        helperText={submitCount ? errors.password : ''}
        label="Hasło"
        fullWidth
        value={password}
        onChange={handleChange}
      />
    </FormControl>
    <FormControl margin="normal" fullWidth>
      <TextField
        name="passwordConfirmation"
        type="password"
        autoComplete="off"
        error={!!submitCount && Boolean(errors.passwordConfirmation)}
        helperText={submitCount ? errors.passwordConfirmation : ''}
        label="Powtórz hasło"
        fullWidth
        value={passwordRepeat}
        onChange={handleChange}
      />
    </FormControl>

    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      disabled={isSubmitting}
      disableRipple={process.env.REACT_APP_BRAND === 'haier'}
    >
      Zmień hasło
    </Button>
  </form>
);

Form.propTypes = {
  values: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  touched: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitCount: PropTypes.number.isRequired,
};

export default Form;
