import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from 'screens/shared/Card/index';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import { withApi } from 'shared/ApiContext';
import Table from './Table';
import Loader from '../../components/Loader';

const RegistrationList = ({ getApplications }) => {
  const [list, setData] = useState(null);
  useEffect(() => {
    getApplications().then(setData);
  }, []);

  return (
    <Card width={700}>
      <Typography component="h1" variant="h5">
        Lista zgłoszeń
      </Typography>

      <Button
        style={{ position: 'absolute', right: 24, top: 16 }}
        variant="contained"
        color="primary"
        component={Link}
        to="/"
        disableRipple={process.env.REACT_APP_BRAND === 'haier'}
      >
        Nowe zgłoszenie
      </Button>

      {list ? <Table data={list} /> : <Loader />}
    </Card>
  );
};

RegistrationList.propTypes = {
  getApplications: PropTypes.func.isRequired,
};

export default withApi(RegistrationList);
