import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const snackbarStyles = () => ({
  root: {
    zIndex: 9999,
  },
});

const styles = (theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    height: '43px',
  },
});

const ToastContent = ({ classes, message, onClose, variant, ...other }) => {
  const Icon = variantIcon[variant];
  return (
    <SnackbarContent
      className={classes[variant]}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
};

ToastContent.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const ToastContentWrapper = withStyles(styles)(ToastContent);
const SnackbarWrapper = withStyles(snackbarStyles)(Snackbar);
class Toast extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
  };

  state = {
    open: false,
    variant: '', // ['success', 'error', 'warning', 'info']
    message: '',
  };

  handleClick = ({ variant, message }) => {
    this.setState({ open: true, variant, message });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ open: false });
  };

  render() {
    const { children } = this.props;
    const { open, variant, message } = this.state;

    return (
      <Fragment>
        {children({ showToast: this.handleClick, hideToast: this.handleClose })}
        <SnackbarWrapper
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={open}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <ToastContentWrapper
            onClose={this.handleClose}
            variant={variant}
            message={message}
          />
        </SnackbarWrapper>
      </Fragment>
    );
  }
}

export default Toast;
