import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

import intl from "config/intl.json";
import Form from "./Form";

const validationSchema = Yup.object({
  email: Yup.string()
    .email(intl.validation.email)
    .required(intl.validation.required),
});

const initValues = { email: "" };

const RestorePassword = ({ restorePassword }) => (
  <Fragment>
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={restorePassword}
      render={(props) => <Form {...props} />}
    />

    <Button
      component={Link}
      to="/auth/zaloguj"
      style={{ marginTop: 30 }}
      disableRipple={process.env.REACT_APP_BRAND === "haier"}
    >
      Wróć do Logowania
    </Button>
  </Fragment>
);

RestorePassword.propTypes = {
  restorePassword: PropTypes.func.isRequired,
};

export default RestorePassword;
