import styled, { css } from "styled-components";
import Link from "@material-ui/core/Link";
import { SocialIcon } from "react-social-icons";
import { media } from "../../../style/mediaTemplate";
import { ReactComponent as HooverLogo } from "../../../config/resources/hoover/logo/hoover-logo.svg";
import { ReactComponent as HaierLogo } from "../../../config/resources/haier/logo/haier-logo.svg";

export const StyledFooter = styled.footer`
  text-align: center;
  background-color: ${(props) => props.theme.footerBackgroundColor};
  color: ${(props) => props.theme.footerTextColor};
  margin-top: 80px;
  padding-bottom: 10px;
`;

export const StyledFooterColumns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  padding-top: 25px;
  padding-bottom: 25px;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: unset;
  }
`;

export const StyledFooterBlock = styled.div`
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  flex-direction: column;
  padding: 15px 0;
  display: flex;
  align-items: center;


  ${(props) =>
    !props.columnDesign &&
    css`
      margin: 40px 0 10px 0;
      flex-direction: column-reverse;
    `}

  ${(props) =>
    props.columnDesign &&
    css`
      align-items: flex-start;

      @media (max-width: 900px) {
        align-items: center;
      }
    `} 

  ${(props) =>
    props.noMargin &&
    css`
      margin: 0 !important;
    `} 


  ${media.tablet`flex-direction: column;`};
  ${media.tablet`margin: 15px 0 10px 0;`};
`;

export const StyledHooverLogo = styled(HooverLogo)`
  width: 56px;
  height: 56px;
  margin: 15px 0;

  ${media.tablet`width: 90px;height: 90px;`};
`;

export const StyledHaierLogo = styled(HaierLogo)`
  width: 87px;
  height: 37px;
  margin: 15px 0;
  fill: #ffffff;
`;

export const StyledCandyLogo = styled.img`
  width: 150px;
  height: auto;
  margin: 15px 0;

  ${media.tablet`width: 200px;`};
`;

export const StyledLink = styled(Link)`
  cursor: pointer !important;
  display: block !important;
  -webkit-transition: color 0.1s ease-in-out;
  transition: color 0.1s ease-in-out;
  margin: 11px 0 !important;

  ${({ $asUppercase }) =>
    $asUppercase === false &&
    css`
      text-transform: none;
    `}

  &:hover {
    text-decoration: none !important;
    color: #ababab;
  }
`;

export const StyledFooterElement = styled.div`
  a,
  span {
    color: ${(props) => props.theme.footerTextColor} !important;
  }
  ${media.desktop`
  display: flex;
  align-items: center;`};
`;

export const StyledLinkWrapper = styled.div`
  margin: 0 0 30px 0;
  ${media.desktop`
  display: flex;
  align-items: center;
  margin: 0`};
`;

export const Separator = styled.span`
  display: none;
  margin: 0 15px;

  ${media.desktop`display:block;`};
`;

export const StyledSocialIcon = styled(SocialIcon)`
  width: 70px !important;
  height: 70px !important;
  margin-left: 7px;
  .social-svg-icon {
    fill: ${(props) => props.theme.footerTextColor} !important;
  }
  ${media.desktop`width: 35px !important;height: 35px !important;`};
`;

export const SocialSubtitle = styled.p`
  display: none;
  ${(props) =>
    props.columnDesign &&
    css`
      margin-top: 0;
      margin-bottom: 4px;
    `}

  ${media.desktop`display: block;`};
`;

export const StyledFooterInfo = styled.small`
  color: ${(props) => props.theme.greyText} !important;
`;
