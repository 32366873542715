import React from 'react';
import brandTheme from '../../../style/themes/brandTheme';
import { StyledCardWrapper, StyledLink } from './style/page404';

export default () => (
  <StyledCardWrapper>
    <h1>404</h1>
    <h4>
      Nie znaleźliśmy strony.
      <br /> Przejdź na{' '}
      <StyledLink theme={brandTheme} href="/">
        stronę głowną
      </StyledLink>
      .
    </h4>
  </StyledCardWrapper>
);
