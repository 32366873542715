import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import { withApi } from 'shared/ApiContext';
import Card from 'screens/shared/Card/index';

import SignIn from './SignIn';
import ResetPassword from './RestorePassword';
import SetPassword from './SetPassword';

const TITLES = [
  ['zaloguj', 'Zaloguj'],
  ['haslo-reset', 'Zresetuj hasło'],
  ['haslo-ustaw', 'Ustaw nowe hasło'],
];

const PageTitle = (props) => {
  const found = TITLES.find((title) => props.location.pathname.match(title[0]));
  return found ? found[1] : null;
};

const LogOut = ({ signOut }) => {
  signOut();
  return <Redirect to={{ pathname: '/' }} />;
};

LogOut.propTypes = {
  signOut: PropTypes.func.isRequired,
};

const renderComponent = (Component, props) => (routeProps) =>
  props.isAuth ? (
    <Redirect to={{ pathname: '/rejestracja/lista' }} />
  ) : (
    <Component {...props} {...routeProps} />
  );

const Auth = ({ match, ...props }) => {
  return (
    <Card width={400}>
      <Typography component="h1" variant="h5">
        <Route component={PageTitle} />
      </Typography>

      <Switch>
        <Route
          path={`${match.url}/zaloguj`}
          render={renderComponent(SignIn, props)}
        />
        <Route
          path={`${match.url}/haslo-reset`}
          render={renderComponent(ResetPassword, props)}
        />
        <Route
          path={`${match.url}/haslo-ustaw/:token`}
          render={renderComponent(SetPassword, props)}
        />
        <Route
          path={`${match.url}/wyloguj`}
          render={() => <LogOut {...props} />}
        />
      </Switch>
    </Card>
  );
};

Auth.propTypes = {
  match: PropTypes.object.isRequired,
  isAuth: PropTypes.bool.isRequired,
  signIn: PropTypes.func.isRequired,
  restorePassword: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
};

export default withApi(Auth);
