import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

const Form = ({
  values: { email, password },
  errors,
  touched,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => (
  <form onSubmit={handleSubmit}>
    <FormControl margin="normal" fullWidth>
      <TextField
        name="email"
        autoComplete="email"
        autoFocus
        error={touched.email && Boolean(errors.email)}
        label="Email"
        fullWidth
        value={email}
        helperText={touched.email ? errors.email : ''}
        onChange={handleChange}
      />
    </FormControl>
    <FormControl margin="normal" fullWidth>
      <TextField
        name="password"
        type="password"
        autoComplete="current-password"
        error={touched.password && Boolean(errors.password)}
        label="Hasło"
        fullWidth
        value={password}
        helperText={touched.password ? errors.password : ''}
        onChange={handleChange}
      />
    </FormControl>
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      disabled={isSubmitting}
      disableRipple={process.env.REACT_APP_BRAND === 'haier'}
    >
      Zaloguj
    </Button>
  </form>
);

Form.propTypes = {
  values: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  touched: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default Form;
