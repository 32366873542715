import React, { useState } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Card from "screens/shared/Card/index";

const list = [
  { key: "product_name", label: "Model urządzenia" },
  { key: "promotion_name", label: "Rodzaj gwarancji" },
  { key: "serial_number", label: "Numer seryjny" },
  { key: "purchased_price", label: "Kwota zakupu" },
  { key: "purchased_on", label: "Data zakupu" },
  { key: "warranty_ends_on", label: "Data ważności gwarancji" },
  { key: "certificate_number", label: "Numer certyfikatu" },
];

const renderValue = (value) => {
  const dateTime = DateTime.fromISO(value);
  return dateTime.isValid ? dateTime.toFormat("yyyy-MM-dd") : value;
};

const Details = ({ getCertificate, data, isAuth, goBack }) => {
  const [isDownloading, setData] = useState(false);
  const download = async () => {
    setData(true);
    try {
      await getCertificate(data);
    } finally {
      setData(false);
    }
  };

  return (
    <Card width={800}>
      <Typography component="h1" variant="h5">
        Karta urządzenia
      </Typography>

      <ul>
        {list
          .filter(({ key }) => data[key])
          .map(({ key, label }) => (
            <li key={key}>
              <b>{label}:</b> {renderValue(data[key])}
            </li>
          ))}
      </ul>

      {isAuth && (
        <Button onClick={isDownloading ? null : download}>
          {isDownloading
            ? "Przygotowuję plik do pobrania..."
            : "POBIERZ CERTYFIKAT"}
        </Button>
      )}

      <Button
        color="primary"
        style={{ marginTop: 30 }}
        onClick={goBack}
        disableRipple={process.env.REACT_APP_BRAND === "haier"}
      >
        WRÓĆ
      </Button>
    </Card>
  );
};

Details.propTypes = {
  data: PropTypes.object.isRequired,
  isAuth: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  getCertificate: PropTypes.func.isRequired,
};

export default Details;
