import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import intl from '../../../config/intl';
import Form from './Form';

const validationSchema = Yup.object({
  email: Yup.string()
    .email(intl.validation.email)
    .required(intl.validation.required),
  password: Yup.string().required(intl.validation.required),
});

const initValues = { email: '', password: '' };

const SignIn = ({ signIn }) => (
  <React.Fragment>
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={signIn}
      component={Form}
    />

    <Button
      component={Link}
      color="primary"
      to="/auth/haslo-reset"
      style={{ marginTop: 30 }}
      disableRipple={process.env.REACT_APP_BRAND === 'haier'}
    >
      Nie pamiętam hasła
    </Button>
  </React.Fragment>
);

SignIn.propTypes = {
  signIn: PropTypes.func.isRequired,
};

export default SignIn;
