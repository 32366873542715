import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import labels from "form/labels";

const Form = ({
  values,
  errors,
  touched,
  handleChange,
  isValid,
  handleSubmit,
  isSubmitting,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <FormControl margin="normal" required fullWidth>
        <InputLabel
          htmlFor="serial_number"
          required={false}
          style={{ fontSize: "0.9rem" }}
        >
          {labels.serial_number}
        </InputLabel>
        <Input
          name="serial_number"
          type="text"
          id="serial_number"
          autoComplete="current-password"
          error={touched.serial_number && Boolean(errors.serial_number)}
          fullWidth
          value={values.serial_number}
          onChange={handleChange}
        />
      </FormControl>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={!isValid || isSubmitting}
        disableRipple={process.env.REACT_APP_BRAND === "haier"}
      >
        Sprawdź
      </Button>
    </form>
  );
};

Form.propTypes = {
  values: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  touched: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default Form;
