import React from "react";

const BrandComponent = ({
  renderCandy: CandyComponent,
  renderHoover: HooverComponent,
  renderHaier: HaierComponent,
}) => {
  const { REACT_APP_BRAND } = process.env;
  let component;

  switch (REACT_APP_BRAND) {
    case "candy":
      component = <CandyComponent />;
      break;
    case "hoover":
      component = <HooverComponent />;
      break;
    case "haier":
      component = <HaierComponent />;
      break;
    default:
      break;
  }

  return component;
};

export default BrandComponent;