import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import brandTheme from '../../style/themes/brandTheme';

const LoaderBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.primaryBrandColor};
  margin-top: 30px;
`;

const Loader = () => (
  <LoaderBox theme={brandTheme}>
    <CircularProgress color="inherit" />
  </LoaderBox>
);

export default Loader;