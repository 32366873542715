import React from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import {
  CloseIconButton,
  MobileNavBlock,
  MobileNavContent,
  MobileNavWrapper,
  StyledMobileMenuButton,
} from './style/toolbar';
import brandTheme from '../../style/themes/brandTheme';

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuData: this.props.menuData,
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick = () => {
    this.props.callback(false);
    document.body.classList.remove('mobile-modal-open');
  };

  render() {
    const { open } = this.props;
    return (
      <MobileNavWrapper className={open ? 'is-open' : null}>
        <MobileNavBlock className={open ? 'is-open' : null}>
          <MobileNavContent className={open ? 'is-open' : null}>
            <CloseIconButton aria-label="close" onClick={this.onClick}>
              <CloseIcon />
            </CloseIconButton>
            {this.state.menuData.map((menuElement) => (
              <StyledMobileMenuButton
                theme={brandTheme}
                component={Link}
                to={menuElement.url}
                key={menuElement.title}
                onClick={this.onClick}
              >
                {menuElement.title}
              </StyledMobileMenuButton>
            ))}
          </MobileNavContent>
        </MobileNavBlock>
      </MobileNavWrapper>
    );
  }
}

MobileMenu.propTypes = {
  callback: PropTypes.func,
  menuData: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
};

export default MobileMenu;
