import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import intl from 'config/intl.json';

const DataTable = ({ data }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Data zgłoszenia</TableCell>
        <TableCell align="right">Nr seryjny</TableCell>
        <TableCell align="right">Status</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {data.map((row) => (
        <TableRow key={row.id}>
          <TableCell>
            {DateTime.fromISO(row.created_at).toFormat('yyyy-MM-dd')}
          </TableCell>
          <TableCell align="right">{row.serial_number}</TableCell>
          <TableCell align="right">
            {row.state === 'approved' ? (
              <Link to={`/produkt/${row.id}`}>{intl.statuses[row.state]}</Link>
            ) : (
              intl.statuses[row.state]
            )}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

DataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DataTable;
