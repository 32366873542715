import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import Typography from "@material-ui/core/Typography";

import intl from "config/intl.json";

import { withApi } from "shared/ApiContext";
import Card from "screens/shared/Card/index";
import Form from "./Form";
import Details from "./Details";
import Loader from "components/Loader";
import validations from "form/validations";

const validationSchema = Yup.object({
  ...validations,
});

class Product extends Component {
  static propTypes = {
    getApplicationById: PropTypes.func.isRequired,
    getApplicationBySerial: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isAuth: PropTypes.bool.isRequired,
  };

  initValues = { serial_number: "" };

  state = { message: "" };

  async componentDidMount() {
    const { id } = this.props.match.params;
    if (!id) return;
    this.setState({ isLoading: true, message: null });
    const { data } = await this.props.getApplicationById(id);
    this.setState({ data, isLoading: false });
  }

  getApplication = async (values) => {
    const { data } = await this.props.getApplicationBySerial(
      values.serial_number,
    );
    const newState =
      data.state === "approved"
        ? { data }
        : { message: intl.statusesMessages[data.state] };
    this.setState({ ...newState, isLoading: false });
  };

  submit = async (values) => {
    try {
      this.setState({ isLoading: true, message: null });
      this.initValues = values;
      await this.getApplication(values);
    } catch (err) {
      if (err.response.status === 404) {
        this.setState({
          message: intl.statusesMessages.notFound,
          isLoading: false,
        });
      }
    }
  };

  goBack = () => {
    if (this.props.match.params.id) {
      this.props.history.push("/rejestracja/lista");
    } else {
      this.setState({ data: null });
    }
  };

  render() {
    const { data, message, isLoading } = this.state;
    if (data) {
      return <Details {...this.props} data={data} goBack={this.goBack} />;
    }

    return (
      <Card width={400}>
        {isLoading ? (
          <Loader />
        ) : (
          <Fragment>
            <Typography component="h1" variant="h5">
              Sprawdź urządzenie
            </Typography>

            <Formik
              initialValues={this.initValues}
              validationSchema={validationSchema}
              onSubmit={this.submit}
              render={(props) => <Form {...props} />}
            />

            {message && (
              <Typography
                component="h3"
                variant="h6"
                style={{ marginTop: 30, textAlign: "center" }}
              >
                {message}
              </Typography>
            )}
          </Fragment>
        )}
      </Card>
    );
  }
}

export default withApi(Product);
