import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { DateTime } from 'luxon';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import intl from 'config/intl.json';
import { withApi } from 'shared/ApiContext';
import Card from 'screens/shared/Card/index';
import { Link } from 'react-router-dom';
import Loader from 'components/Loader';
import validations from 'form/validations';
import Form from './Form';

const schemaUser = {
  user_first_name: Yup.string().required(intl.validation.required),
  user_last_name: Yup.string().required(intl.validation.required),
  user_email: Yup.string()
    .email(intl.validation.email)
    .required(intl.validation.required),
  user_password: Yup.string().when('createAccount', {
    is: true,
    then: Yup.string()
      .min(8, intl.validation.passwordLength)
      .required(intl.validation.required),
    otherwise: Yup.string().notRequired(),
  }),
  passwordConfirmation: Yup.string().when('createAccount', {
    is: true,
    then: Yup.string()
      .required(intl.validation.required)
      .oneOf([Yup.ref('user_password')], intl.validation.passwordMismatch),
    otherwise: Yup.string().notRequired(),
  }),
};

const schema = {
  product_id: Yup.string().required(intl.validation.required),
  purchased_on: Yup.date().required(intl.validation.required),
  purchased_price: Yup.number().required(intl.validation.required),
  purchased_location: Yup.string().required(intl.validation.required),
  receiptType: Yup.string()
    .required(intl.validation.required)
    .oneOf(
      ['image/jpeg', 'image/png', 'application/pdf'],
      intl.validation.receipt.type,
    ),
  receiptSize: Yup.number()
    .required(intl.validation.required)
    .moreThan(1, intl.validation.receipt.moreThan)
    .lessThan(5000000, intl.validation.receipt.lessThan),
  data_processing_consent: Yup.boolean().oneOf(
    [true],
    intl.validation.requiredCheckbox,
  ),
  ...validations,
};

const initValues = {
  serial_number: '',
  receipt: null,
  product_id: '',
  purchased_on: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
  purchased_price: '',
  purchased_location: '',
  data_processing_consent: false,
  marketing_consent: false,
  user_first_name: '',
  user_last_name: '',
  user_email: '',
  user_password: '',
  passwordConfirmation: '',
  createAccount: false,
};

class RegistrationForm extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    submitApplication: PropTypes.func.isRequired,
    getPromotionDetails: PropTypes.func.isRequired,
    isAuth: PropTypes.bool.isRequired,
  };

  state = {};

  constructor(props) {
    super(props);
    this.validationSchema = Yup.object({
      ...schema,
      ...(props.isAuth ? {} : schemaUser),
    });
    this.initialValues = {
      ...initValues,
      ...props.match.params,
      createAccount: !props.isAuth,
    };
  }

  async componentDidMount() {
    const promotionDetails = await this.props.getPromotionDetails(
      this.props.match.params.promotion_uuid,
    );
    this.setState({ promotionDetails });
  }

  submit = async (values, form) => {
    this.setState({ status: null });
    const status = await this.props.submitApplication(values, form);
    this.setState({ status });
  };

  renderContent = () => {
    const { promotionDetails, status } = this.state;
    const { isAuth } = this.props;
    if (!status || status === 'isUsed') {
      return (
        <Fragment>
          <Formik
            initialValues={this.initialValues}
            validationSchema={this.validationSchema}
            onSubmit={this.submit}
            render={(props) => (
              <Form
                {...props}
                isAuth={isAuth}
                products={promotionDetails.products_by_category}
                statuteUrl={promotionDetails.statute_url}
              />
            )}
          />
          {status === 'isUsed' && (
            <Button
              component={Link}
              color="secondary"
              variant="outlined"
              to="/auth/zaloguj"
              style={{ marginTop: 30 }}
              disableRipple={process.env.REACT_APP_BRAND === 'haier'}
            >
              Użytkownik już istnieje w systemie. Kliknij by się zalogować.
            </Button>
          )}
        </Fragment>
      );
    }

    return (
      <div style={{ textAlign: 'center' }}>
        <Typography component="h2" variant="h6" style={{ marginTop: 50 }}>
          Zgłoszenie zostało przyjęte do weryfikacji
        </Typography>

        <div style={{ marginTop: 30 }}>
          <Button
            component={Link}
            color="primary"
            variant="outlined"
            to="/"
            style={{ marginRight: 30 }}
            disableRipple={process.env.REACT_APP_BRAND === 'haier'}
          >
            Powrót do strony głównej
          </Button>

          <Button
            component={Link}
            color="primary"
            variant="outlined"
            to={isAuth ? '/rejestracja/lista' : '/auth/zaloguj'}
            disableRipple={process.env.REACT_APP_BRAND === 'haier'}
          >
            {isAuth ? 'Lista zgłoszeń' : 'Zaloguj się'}
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const { promotionDetails } = this.state;
    if (!promotionDetails) return <Loader />;
    return (
      <Card>
        <Typography component="h1" variant="h5">
          Rejestracja urządzenia: {promotionDetails.name}
        </Typography>
        {this.renderContent()}
      </Card>
    );
  }
}

export default withApi(RegistrationForm);
