import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

const Field = ({
  xs,
  sm,
  errors,
  onChange,
  name,
  label,
  values,
  type,
  autoComplete,
  submitCount,
}) => (
  <Grid item xs={xs} sm={sm}>
    <FormControl margin="normal" fullWidth>
      <TextField
        id={name}
        name={name}
        label={label}
        helperText={submitCount ? errors[name] : ''}
        error={!!submitCount && Boolean(errors[name])}
        fullWidth
        value={values[name]}
        onChange={onChange(name)}
        type={type}
        InputLabelProps={{ shrink: true }}
        autoComplete={autoComplete}
      />
    </FormControl>
  </Grid>
);

Field.defaultProps = {
  type: 'text',
};

const CheckboxLabel = ({ url }) => {
  return (
    <span>
      Wyrażam zgodę na przetwarzanie moich danych osobowych przez administratora
      strony Candy w celu realizacji promocji i wydania przedłużonej gwarancji.
      Administrator danych będzie przetwarzał dane również w celach
      marketingowych, na podstawie art. 23 ust. 4 pkt 4 ustawy o ochronie danych
      osobowych. Przyjmuję do wiadomości, że podanie danych i wyrażenie zgody na
      ich przetwarzanie jest dobrowolne, jednak jest wymagane, aby otrzymać
      przedłużoną gwarancję w promocji. Ponadto przyjmuję do wiadomości, że mam
      prawo wglądu do swoich danych oraz do ich poprawiania. Oświadczam, że
      zapoznałem się z{' '}
      <a href={url} target="_blank" rel="noopener noreferrer">
        regulaminem promocji
      </a>
      .
    </span>
  );
};

const CHECKBOX_2 = `Wyrażam zgodę na przesyłanie na podany przeze mnie w niniejszym kuponie adres e-mail informacji handlowych i marketingowych dotyczących produktów Candy, Hoover, Haier.`;

const Form = ({
  classes,
  handleChange,
  isValid,
  setFieldValue,
  handleSubmit,
  isSubmitting,
  isAuth,
  products,
  statuteUrl,
  ...props
}) => (
  <form className={classes.form} onSubmit={handleSubmit}>
    <Grid container spacing={8}>
      {!isAuth && (
        <Fragment>
          <Field
            {...props}
            xs={12}
            sm={4}
            onChange={handleChange}
            name="user_first_name"
            label="Imię"
          />
          <Field
            {...props}
            xs={12}
            sm={4}
            onChange={handleChange}
            name="user_last_name"
            label="Nazwisko"
          />

          <Field
            {...props}
            xs={12}
            sm={4}
            onChange={handleChange}
            name="user_email"
            label="Email"
            autoComplete="username email"
          />
        </Fragment>
      )}

      <Grid item sm={6} xs={12}>
        <FormControl
          margin="normal"
          fullWidth
          error={!!props.submitCount && Boolean(props.errors.product_id)}
        >
          <InputLabel htmlFor="product_id" shrink>
            Wybierz model urządzenia
          </InputLabel>
          <Select onChange={handleChange} name="product_id" native>
            <option />
            {Object.keys(products).map((key) => (
              <optgroup key={key} label={key}>
                {products[key].map(({ id, name }) => (
                  <option key={`${key}-${id}`} value={id}>
                    {name}
                  </option>
                ))}
              </optgroup>
            ))}
          </Select>
          <FormHelperText>
            {props.submitCount ? props.errors.product_id : ''}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Field
        {...props}
        xs={12}
        sm={6}
        onChange={handleChange}
        name="serial_number"
        label="Numer seryjny"
      />

      <Field
        {...props}
        xs={12}
        sm={3}
        onChange={handleChange}
        name="purchased_on"
        label="Data zakupu"
        type="date"
      />

      <Field
        {...props}
        xs={12}
        sm={2}
        onChange={handleChange}
        name="purchased_price"
        label="Kwota zakupu"
        type="number"
      />

      <Field
        {...props}
        xs={12}
        sm={7}
        onChange={handleChange}
        name="purchased_location"
        label="Miejsce zakupy (adres, strona WWW)"
      />

      <Grid item xs={12}>
        <FormControl
          margin="normal"
          fullWidth
          error={
            !!props.submitCount &&
            Boolean(props.errors.receiptType || props.errors.receiptSize)
          }
        >
          <InputLabel htmlFor="receipt" shrink>
            Kopia paragonu/FV (plik w formacie .jpg .jpeg .png .pdf, max 5Mb)
          </InputLabel>

          <label htmlFor="recipe-file" style={{ paddingTop: 20 }}>
            <input
              accept="image/*,application/pdf"
              style={{ display: 'none' }}
              id="recipe-file"
              name="receipt"
              type="file"
              onChange={(e) => {
                const [file] = e.target.files;

                if (file) {
                  setFieldValue('receipt', file);
                  setFieldValue('receiptType', file ? file.type : null);
                  setFieldValue('receiptSize', file ? file.size : null);
                }
              }}
            />

            <Button
              component="span"
              color="primary"
              variant="contained"
              disableRipple={process.env.REACT_APP_BRAND === 'haier'}
            >
              {props.values.receipt
                ? props.values.receipt.name
                : 'Wybierz plik'}
            </Button>
          </label>

          <FormHelperText>
            {props.submitCount
              ? props.errors.receiptSize || props.errors.receiptType
              : ''}
          </FormHelperText>
        </FormControl>
      </Grid>

      {!isAuth && (
        <React.Fragment>
          <Field
            {...props}
            xs={12}
            sm={6}
            onChange={handleChange}
            name="user_password"
            label="Hasło"
            type="password"
            autoComplete="new-password"
          />

          <Field
            {...props}
            xs={12}
            sm={6}
            onChange={handleChange}
            name="passwordConfirmation"
            label="Powtórz hasło"
            type="password"
            autoComplete="new-password"
          />
        </React.Fragment>
      )}
    </Grid>

    <div style={{ padding: '20px 0' }}>
      <FormGroup>
        <FormControl
          error={
            !!props.submitCount && Boolean(props.errors.data_processing_consent)
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                name="data_processing_consent"
                onChange={handleChange}
                checked={props.values.checkbox1}
              />
            }
            label={<CheckboxLabel url={statuteUrl} />}
          />
          <FormHelperText>
            {props.submitCount ? props.errors.data_processing_consent : ''}
          </FormHelperText>
        </FormControl>

        <FormControlLabel
          control={
            <Checkbox
              name="marketing_consent"
              onChange={handleChange}
              checked={props.values.marketing_consent}
            />
          }
          label={CHECKBOX_2}
        />
      </FormGroup>
    </div>

    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={classes.submit}
      disabled={isSubmitting}
      disableRipple={process.env.REACT_APP_BRAND === 'haier'}
    >
      Wyślij
    </Button>
  </form>
);

Form.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitCount: PropTypes.number.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isAuth: PropTypes.bool.isRequired,
  products: PropTypes.object.isRequired,
  statuteUrl: PropTypes.string,
};

Form.defaultProps = {
  statuteUrl: null,
};

export default withStyles(styles)(Form);
