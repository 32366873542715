import * as Yup from 'yup';
import intl from 'config/intl.json';

const baseSerialNumberValidation = Yup.string()
  .matches(/^[0-9]{16}$/, 'Numer seryjny musi zawierać 16 cyfr')
  .required(intl.validation.required);

const validations = {
  candy: {
    serial_number: Yup.string()
      .min(16, 'Numer musi zawierać min. 16 znaków')
      .transform((value, originalValue) =>
        /\s/.test(originalValue) ? '' : value,
      )
      .required(intl.validation.required),
  },
  hoover: {
    serial_number: baseSerialNumberValidation,
  },
  haier: {
    serial_number: Yup.string()
      .min(16, 'Numer musi zawierać min. 16 znaków')
      .transform((value, originalValue) =>
        /\s/.test(originalValue) ? '' : value,
      )
      .required(intl.validation.required),
  },
};

export default validations[process.env.REACT_APP_BRAND];
