const labels = {
  candy: {
    serial_number: 'Numer urządzenia (min. 16 znaków)',
  },
  hoover: {
    serial_number: 'Numer seryjny urządzenia (16 cyfr)',
  },
  haier: {
    serial_number: 'Numer urządzenia (min. 16 znaków)',
  },
};

export default labels[process.env.REACT_APP_BRAND];
