import axios from 'axios';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import intl from 'config/intl.json';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const setAuthorization = (token) => {
  axiosInstance.defaults.headers.common.Authorization = token;
};

const ApiContext = React.createContext();

const getMessageFromError = (err) =>
  err.response.data.error || intl.toasts.server500;

class Api extends Component {
  static propTypes = {
    showToast: PropTypes.func.isRequired,
    hideToast: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = { token: null };

  constructor(props) {
    super(props);
    const token = window.sessionStorage.getItem('token');
    this.state = { token };
    if (this.state.token) setAuthorization(this.state.token);
  }

  catchError = (err) => {
    const message = getMessageFromError(err);
    this.props.showToast({ variant: 'error', message });
  };

  setToken = (token) => {
    setAuthorization(token);
    window.sessionStorage.setItem('token', token);
    return new Promise((resolve) => {
      this.setState({ token }, resolve);
    });
  };

  signIn = async (values, { setSubmitting }) => {
    try {
      const res = await axiosInstance.post('/login', {
        ...values,
        brand: process.env.REACT_APP_BRAND,
      });
      this.setToken(res.data.access_token);
    } catch (err) {
      this.catchError(err);
    } finally {
      setSubmitting(false);
    }
  };

  restorePassword = async (values, { setSubmitting, resetForm }) => {
    try {
      await axiosInstance.post('/passwords/restore', {
        ...values,
        brand: process.env.REACT_APP_BRAND,
      });
      this.props.showToast({
        variant: 'success',
        message: intl.toasts.password.restoreSuccess,
      });
      resetForm();
    } catch (err) {
      this.catchError(err);
    } finally {
      setSubmitting(false);
    }
  };

  setPassword = async (values, { setSubmitting }) => {
    try {
      await axiosInstance.post('/passwords/reset', values);
      this.props.showToast({
        variant: 'success',
        message: intl.toasts.password.resetSuccess,
      });
    } catch (err) {
      this.catchError(err);
      throw err;
    } finally {
      setSubmitting(false);
    }
  };

  signOut = () => {
    window.sessionStorage.removeItem('token');
    this.setState({ token: null });
  };

  getApplications = async () => {
    try {
      const res = await axiosInstance.get('/applications');
      return res.data;
    } catch (err) {
      this.catchError(err);
      this.signOut();
      this.props.history.push('/');
      throw err;
    }
  };

  getApplicationById = async (id) => {
    try {
      return await axiosInstance.get(`/applications/${id}`);
    } catch (err) {
      this.catchError(err);
      throw err;
    }
  };

  getApplicationBySerial = async (serialNumber) => {
    try {
      return await axiosInstance.get(
        `/applications/show_by_serial_number?serial_number=${serialNumber}&brand=${
          process.env.REACT_APP_BRAND
        }`,
      );
    } catch (err) {
      if (err.response.status === 500) {
        this.catchError(err);
      }
      throw err;
    }
  };

  getPromotionDetails = async (uuid) => {
    try {
      const res = await axiosInstance.get(`/promotions/${uuid}`);
      return res.data;
    } catch (err) {
      this.catchError(err);
      throw err;
    }
  };

  submitApplication = async (values, { setSubmitting }) => {
    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (!values[key] && typeof values[key] !== 'boolean') return;
        formData.append(key, values[key]);
      });

      formData.append('brand', process.env.REACT_APP_BRAND);
      const url = `/applications${
        this.state.token ? '/create_authenticated' : ''
      }`;
      const res = await axiosInstance.post(url, formData);
      if (res.data.user_token) {
        await this.setToken(res.data.user_token);
      }
      return 'success';
    } catch (err) {
      const msg = getMessageFromError(err);
      if (msg === 'Użytkownik już istnieje w systemie') {
        return 'isUsed';
      }

      this.catchError(err);
      throw err;
    } finally {
      setSubmitting(false);
    }
  };

  getCertificate = async ({ id }) => {
    try {
      const { data } = await axiosInstance({
        url: `/certificates/${id}.pdf`,
        method: 'GET',
        responseType: 'blob',
      });
      const blob = new Blob([data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'certyfikat.pdf';
      link.click();
    } catch (err) {
      this.catchError(err);
      throw err;
    }
  };

  render() {
    return (
      <ApiContext.Provider
        value={{
          isAuth: !!this.state.token,
          signIn: this.signIn,
          signOut: this.signOut,
          getApplications: this.getApplications,
          getApplicationById: this.getApplicationById,
          getApplicationBySerial: this.getApplicationBySerial,
          submitApplication: this.submitApplication,
          getPromotionDetails: this.getPromotionDetails,
          restorePassword: this.restorePassword,
          setPassword: this.setPassword,
          getCertificate: this.getCertificate,
        }}
      >
        {this.props.children}
      </ApiContext.Provider>
    );
  }
}
const ApiConsumer = ApiContext.Consumer;

const ApiProvider = withRouter(Api);

const withApi = (Comp) => (props) => (
  <ApiConsumer>{(apiProps) => <Comp {...apiProps} {...props} />}</ApiConsumer>
);

export { ApiProvider, ApiConsumer, withApi };
