import React from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "@material-ui/core/AppBar";
import { ApiConsumer } from "shared/ApiContext";
import MobileMenu from "./MobileMenu";
import BrandComponent from "../BrandComponent/index";

import candyLogo from "../../config/resources/candy/logo/candy-logo.png";
import brandTheme from "../../style/themes/brandTheme";
import {
  StyledToolbar,
  StyledHooverLogo,
  StyledCandyLogo,
  StyledHaierLogo,
  StyledLogoWrapper,
  StyledMenuButton,
  StyledMenuWrapper,
  MobileManyIconHamburger,
} from "./style/toolbar";

const Logo = () => (
  <Link to="/">
    <StyledLogoWrapper theme={brandTheme}>
      <BrandComponent
        renderCandy={() => <StyledCandyLogo src={candyLogo} alt="candy" />}
        renderHoover={() => <StyledHooverLogo />}
        renderHaier={() => <StyledHaierLogo />}
      />
    </StyledLogoWrapper>
  </Link>
);

class ToolBar extends React.Component {
  static menuData(isAuth) {
    return [
      isAuth
        ? {
            title: "Lista zgłoszeń",
            url: "/rejestracja/lista",
          }
        : null,
      { title: "Sprawdź urządzenie", url: "/produkt" },
      {
        title: isAuth ? "Wyloguj" : "Zaloguj",
        url: isAuth ? "/auth/wyloguj" : "/auth/zaloguj",
      },
    ].filter(Boolean);
  }

  static checkCurrentURL(linkTo) {
    const currentUrl = window.location.href;
    return currentUrl.includes(linkTo);
  }

  state = {
    mobileMenuOpen: false,
  };

  closeMobileMenu = (val) => {
    this.setState({
      mobileMenuOpen: val,
    });
  };

  openMobileMenu = () => {
    this.setState({
      mobileMenuOpen: true,
    });
    document.body.classList.add("mobile-modal-open");
  };

  render() {
    return (
      <AppBar position="static" color="default">
        <ApiConsumer>
          {({ isAuth }) => (
            <StyledToolbar>
              <Logo />
              <StyledMenuWrapper theme={brandTheme}>
                {ToolBar.menuData(isAuth).map((menuElement) => (
                  <StyledMenuButton
                    disableFocusRipple
                    disableRipple
                    theme={brandTheme}
                    component={Link}
                    to={menuElement.url}
                    key={menuElement.title}
                    $withUnderline={process.env.REACT_APP_BRAND === 'haier'}
                    className={
                      ToolBar.checkCurrentURL(menuElement.url) ? "active" : ""
                    }
                  >
                    {menuElement.title}
                  </StyledMenuButton>
                ))}
                <MobileManyIconHamburger onClick={this.openMobileMenu} theme={brandTheme}>
                  <MenuIcon />
                </MobileManyIconHamburger>
              </StyledMenuWrapper>
              <MobileMenu
                menuData={ToolBar.menuData(isAuth)}
                open={this.state.mobileMenuOpen}
                callback={this.closeMobileMenu}
              />
            </StyledToolbar>
          )}
        </ApiConsumer>
      </AppBar>
    );
  }
}

export default ToolBar;
