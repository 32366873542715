import {
  candyBrandColor,
  candyBrandColorSeparator,
  candyFontFamily,
  candyFooterBackgroundColor,
  candyGreyText,
  hooverFontFamily,
  hooverGreyText,
  hoverBrandColor,
  hoverBrandColorSeparator,
  hoverFooterBackgroundColor,
  haierFontFamily,
  haierGreyText,
  haierLightGreyText,
  haierBrandColor,
  haierBrandColorSeparator,
  haierFooterBackgroundColor,
} from './brandsVariables';

const hooverTheme = {
  greyText: hooverGreyText,
  toolbarBtnTextColor: 'white',
  toolbarMobileBtnTextColor: 'black',
  toolbarLogoBgColor: 'white',
  toolbarHamburgerColor: 'inherit',
  primaryBrandColor: hoverBrandColor,
  fontFamily: hooverFontFamily,
  colorSeparator: hoverBrandColorSeparator,
  footerBackgroundColor: hoverFooterBackgroundColor,
  footerTextColor: hoverBrandColor,
  cardBorder: hoverFooterBackgroundColor
};

const candyTheme = {
  greyText: candyGreyText,
  toolbarBtnTextColor: 'white',
  toolbarMobileBtnTextColor: 'black',
  toolbarLogoBgColor: 'white',
  toolbarHamburgerColor: 'inherit',
  primaryBrandColor: candyBrandColor,
  fontFamily: candyFontFamily,
  colorSeparator: candyBrandColorSeparator,
  footerBackgroundColor: candyFooterBackgroundColor,
  footerTextColor: candyBrandColor,
  cardBorder: candyFooterBackgroundColor
};

const haierTheme = {
  greyText: haierGreyText,
  lightGreyText: haierLightGreyText,
  toolbarBtnTextColor: 'white',
  toolbarMobileBtnTextColor: 'black',
  toolbarHamburgerColor: 'white',
  toolbarLogoBgColor: haierFooterBackgroundColor,
  primaryBrandColor: haierBrandColor,
  fontFamily: haierFontFamily,
  colorSeparator: haierBrandColorSeparator,
  footerBackgroundColor: haierFooterBackgroundColor,
  footerTextColor: 'white',
  cardBorder: 'rgba(151,151,151,0.4)',
};

const brandTheme = {
  candy: candyTheme,
  hoover: hooverTheme,
  haier: haierTheme,
};

export default brandTheme[process.env.REACT_APP_BRAND];
