import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

export const StyledCardWrapper = styled.div`
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

export const StyledCardBlock = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: none !important;
  border: 1px solid ${(props) => props.theme.cardBorder};

  button[type='submit'] {
    margin-top: 32px;
  }
`;
