import styled, { css } from "styled-components";
import Button from "@material-ui/core/Button/index";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as HooverLogo } from "../../../config/resources/hoover/logo/hoover-logo.svg";
import { ReactComponent as HaierLogo } from "../../../config/resources/haier/logo/haier-logo.svg";
import { media } from "../../../style/mediaTemplate";

export const StyledToolbar = styled(Toolbar)`
  padding: 0 !important;
  align-items: stretch !important;
  background-color: #fff !important;
`;

const BaseLogoStyles = css`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
`;

export const StyledHooverLogo = styled(HooverLogo)`
  width: 56px;
  height: 56px;
  ${media.desktop`width: 90px;height: 90px;`};
  ${BaseLogoStyles}
`;

export const StyledHaierLogo = styled(HaierLogo)`
  height: 37px;
  width: initial;
  ${BaseLogoStyles}
  fill: white;
  margin-left: 34px;

  ${media.desktop`margin-left:0px;`};
`;

export const StyledCandyLogo = styled.img`
  width: 100px;
  ${media.desktop`width: 150px;`};
  ${BaseLogoStyles}
`;

export const StyledLogoWrapper = styled.div`
  padding: 5px 40px 5px 0;
  position: relative;
  z-index: 0;
  height: 100%;
  background-color: ${(props) => props.theme.toolbarLogoBgColor};

  ${media.phone`width: 104px;`};
  ${media.tablet`width: 116px;`};
  ${media.desktop`width: 164px;`};

  &:before {
    content: "";
    -webkit-transform: skewX(-15deg);
    transform: skewX(-15deg);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background-color: ${(props) => props.theme.toolbarLogoBgColor};
    z-index: -1;
  }
`;

export const StyledMenuButton = styled(Button)`
  padding: 0 20px !important;
  color: ${(props) => props.theme.toolbarBtnTextColor} !important;
  margin-left: 20px !important;
  transition: none !important;
  font-weight: 700 !important;
  font-family: ${(props) => props.theme.fontFamily} !important;
  letter-spacing: 0.05em !important;
  text-align: center;
  display: none !important;
  height: 100%;

  ${({ $withUnderline }) =>
    $withUnderline &&
    css`
      font-weight: 500 !important;

      &.active {
        font-weight: 700 !important;
      }

      &:hover:not(.active) {
        text-underline-position: under !important;
        text-decoration: underline !important;
      }
    `}

    ${({ $withUnderline }) =>
      !$withUnderline &&
      css`
        &:hover,
        &:focus {
          transform: skewX(-15deg);
          background-color: rgba(0, 0, 0, 0.08);

          span {
            transform: skewX(15deg);
          }
        }
      `}

  ${media.desktop`padding: 0 30px !important;margin-left: 30px !important;`};
  ${media.tabletBig`display: inherit !important;margin-right: 15px !important;`};
`;

export const StyledMenuWrapper = styled.div`
  flex: 1;
  background-color: ${(props) => props.theme.primaryBrandColor};
  display: flex;
  justify-content: flex-end;
  align-items: center;

  height: 80px;
  ${media.desktop`height: 100px;`};
`;

// mobileNav
export const MobileNavBlock = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding-left: 40px;
  z-index: 3;
  transition: all 0.8s cubic-bezier(0.77, 0, 0.175, 1) 0s;

  &.is-open {
    background-color: rgba(51, 51, 51, 0.6);
  }

  ${media.tabletBig`display: none`};
`;

export const MobileNavWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 100vh;
  pointer-events: none;

  &.is-open {
    pointer-events: all;
    ${media.tabletBig`display: none`};
  }
`;

export const MobileNavContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  top: 0;
  background-color: white;
  transition: all 0.8s cubic-bezier(0.77, 0, 0.175, 1);
  transform: translate3d(100%, 0, 0);
  visibility: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 50px;

  &.is-open {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
`;

export const StyledMobileMenuButton = styled(Button)`
  display: block;
  color: ${(props) => props.theme.toolbarMobileBtnTextColor} !important;
  font-family: ${(props) => props.theme.fontFamily} !important;
  letter-spacing: 0.05em !important;
  text-align: center;
  width: 100%;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
`;

export const CloseIconButton = styled(IconButton)`
  position: absolute !important;
  top: 0;
  right: 0;
`;

export const MobileManyIconHamburger = styled(IconButton)`
  display: inherit !important;
  margin-right: 10px !important;

  svg {
    color: ${(props) => props.theme.toolbarHamburgerColor} !important;
  }

  ${media.tabletBig`display: none !important;`};
`;
