// fonts
export const hooverFontFamily =
  '"Trebuchet MS","Helvetica","Arial",Tahoma,sans-serif';
export const candyFontFamily =
  '"Trebuchet MS","Helvetica","Arial",Tahoma,sans-serif';
export const haierFontFamily = 'Calibri, "carlitoregular"';

// colors
export const hooverGreyText = "#747474";
export const hoverBrandColor = "#BD2A37";
export const hoverBrandColorSecondary = "#284184";
export const hoverBrandColorSeparator = "#bfbfbf";
export const hoverFooterBackgroundColor = "#ddd";

export const candyGreyText = "#747474";
export const candyBrandColor = "#1990cf";
export const candyBrandColorSecondary = "#FFB112";
export const candyBrandColorSeparator = "#bfbfbf";
export const candyFooterBackgroundColor = "#ddd";

export const haierGreyText = "#727171";
export const haierLightGreyText = "#efefef";
export const haierBrandColor = "#000000"; //#727171
export const haierBrandColorSecondary = "#727171";
export const haierBrandColorSeparator = "#bfbfbf";
export const haierFooterBackgroundColor = "#000000";
