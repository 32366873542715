import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Redirect } from 'react-router-dom';

import intl from 'config/intl.json';
import Form from './Form';

const validationSchema = Yup.object({
  password: Yup.string()
    .min(8, intl.validation.passwordLength)
    .required(intl.validation.required),
  passwordConfirmation: Yup.string()
    .required(intl.validation.required)
    .oneOf([Yup.ref('password')], intl.validation.passwordMismatch),
});

const initValues = { password: '', passwordConfirmation: '' };

class SetPassword extends Component {
  static propTypes = {
    setPassword: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  };

  state = { isSuccess: false };

  submitValues = async (values, form) => {
    const { params } = this.props.match;
    await this.props.setPassword({ ...params, ...values }, form);
    this.setState({ isSuccess: true });
  };

  render() {
    return this.state.isSuccess ? (
      <Redirect to="/auth/zaloguj" />
    ) : (
      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={this.submitValues}
        render={(props) => <Form {...props} />}
      />
    );
  }
}

export default SetPassword;
