import hooverFooterJson from "./hoover/footer";
import candyFooterJson from "./candy/footer";
import haierFooterJson from "./haier/footer";

const data = {};
switch (process.env.REACT_APP_BRAND) {
  case "hoover":
    data.footer = hooverFooterJson;
    break;
  case "candy":
    data.footer = candyFooterJson;
    break;
  case "haier":
    data.footer = haierFooterJson;
    break;
  default:
    data.footer = null;
    break;
}

export default data;
