import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import BrandComponent from '../BrandComponent/index';

import { generalStyles } from '../../style/general';
import data from '../../config/resources/dataDistribution';

import candyLogo from '../../config/resources/candy/logo/candy-logo.png';

import brandTheme from '../../style/themes/brandTheme';
import {
  StyledFooter,
  StyledHooverLogo,
  StyledCandyLogo,
  StyledHaierLogo,
  StyledFooterBlock,
  StyledLink,
  StyledFooterElement,
  StyledLinkWrapper,
  Separator,
  StyledSocialIcon,
  SocialSubtitle,
  StyledFooterInfo,
  StyledFooterColumns,
} from './style/footer';

const { footerData } = data.footer ? data.footer : null;
const footerLinks = footerData.filter((item) => item.type === 'link');
const { footerInfo } = data.footer ? data.footer : null;
const { socialMedia } = data.footer ? data.footer : null;
const { email } = data.footer ? data.footer : null;

const Logo = () => (
  <BrandComponent
    renderCandy={() => <StyledCandyLogo src={candyLogo} alt="candy" />}
    renderHoover={() => <StyledHooverLogo />}
    renderHaier={() => <StyledHaierLogo />}
  />
);

const SocialMedia = ({ data, withTitle }) => (
  <>
    {withTitle && <SocialSubtitle>{data.subtitle}</SocialSubtitle>}
    {data.links.map((link) => (
      <StyledSocialIcon
        key={link}
        theme={brandTheme}
        url={link}
        bgColor="transparent"
      />
    ))}
  </>
);

const Links = ({ footerLinks, withSeparator, $asUppercase }) => (
  <>
    {footerLinks.map((footerItem) => (
      <StyledFooterElement key={footerItem.title} theme={brandTheme}>
        {footerItem.type !== 'social_media' && (
          <StyledLink
            href={footerItem.url}
            key={footerItem.title}
            $asUppercase={$asUppercase}
          >
            {footerItem.title}
          </StyledLink>
        )}
        {withSeparator && <Separator>&#47;</Separator>}
      </StyledFooterElement>
    ))}
  </>
);

const Email = ({ email, withSeparator, $asUppercase }) => (
  <>
    {email && (
      <StyledFooterElement theme={brandTheme}>
        <StyledLink href={`mailto:${email.url}`} $asUppercase={$asUppercase}>
          {email.subtitle}
          {email.title}
        </StyledLink>

        {withSeparator && <Separator>&#47;</Separator>}
      </StyledFooterElement>
    )}
  </>
);

const RowNavigationDesign = () => {
  return (
    <StyledFooterBlock theme={brandTheme}>
      <Logo />
      <StyledLinkWrapper>
        <Links
          footerLinks={footerLinks}
          withSeparator={email || socialMedia}
          $asUppercase
        />
        <Email email={email} withSeparator={socialMedia} />
        <SocialMedia
          data={socialMedia}
          withTitle={socialMedia.links.length > 0}
        />
      </StyledLinkWrapper>
    </StyledFooterBlock>
  );
};

const ColumnNavigationDesign = () => {
  return (
    <StyledFooterColumns>
      <StyledFooterBlock theme={brandTheme} noMargin>
        <Logo />
      </StyledFooterBlock>
      <StyledFooterBlock theme={brandTheme} columnDesign>
        <div>INFORMACJE PRAWNE</div>
        <Links
          footerLinks={footerLinks}
          withSeparator={false}
          $asUppercase={false}
        />
      </StyledFooterBlock>
      <StyledFooterBlock theme={brandTheme} columnDesign>
        <div>KONTAKT</div>
        <Email email={email} withSeparator={false} $asUppercase={false} />
      </StyledFooterBlock>

      <StyledFooterBlock theme={brandTheme} columnDesign>
        {socialMedia.links.length > 0 ? (
          <SocialSubtitle columnDesign>{socialMedia.subtitle}</SocialSubtitle>
        ) : null}
        <div>
          <SocialMedia data={socialMedia} withTitle={false} />
        </div>
      </StyledFooterBlock>
    </StyledFooterColumns>
  );
};

const FooterComponent = ({ classes }) => (
  <StyledFooter theme={brandTheme}>
    <div className={classNames(classes.layout)}>
      <BrandComponent
        renderCandy={() => <RowNavigationDesign />}
        renderHoover={() => <RowNavigationDesign />}
        renderHaier={() => <ColumnNavigationDesign />}
      />
      <StyledFooterInfo theme={brandTheme}>{footerInfo.text}</StyledFooterInfo>
      <StyledFooterInfo theme={brandTheme} style={{ opacity: 0.5 }}>
        <br />
        v. {process.env.REACT_APP_VERSION}
      </StyledFooterInfo>
    </div>
  </StyledFooter>
);

FooterComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(generalStyles)(FooterComponent);
