import { createMuiTheme } from '@material-ui/core/styles/index';
import {
  candyBrandColor,
  candyBrandColorSecondary,
  candyFontFamily,
  hooverFontFamily,
  hoverBrandColor,
  hoverBrandColorSecondary,
  haierFontFamily,
  haierBrandColor,
  haierBrandColorSecondary,
} from './brandsVariables';

const candyTheme = createMuiTheme({
  palette: {
    primary: {
      main: candyBrandColor,
    },
    secondary: {
      main: candyBrandColorSecondary,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: candyFontFamily,
    h1: {
      fontSize: '40px',
      fontWight: '700',
    },
    h2: {
      fontSize: '32px',
      fontWeight: '700',
    },
    h3: {
      fontSize: '28x',
      fontWeight: '700',
    },
    h4: {
      fontSize: '22px',
      fontWeight: '700',
    },
    h5: {
      fontSize: '18px',
      fontWeight: '700',
    },
    h6: {
      fontSize: '14px',
      fontWeight: '700',
    },
  },
});

const hooverTheme = createMuiTheme({
  palette: {
    primary: {
      main: hoverBrandColor,
    },
    secondary: {
      main: hoverBrandColorSecondary,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: hooverFontFamily,
    h1: {
      fontSize: '40px',
      fontWight: '700',
    },
    h2: {
      fontSize: '32px',
      fontWeight: '700',
    },
    h3: {
      fontSize: '28x',
      fontWeight: '700',
    },
    h4: {
      fontSize: '22px',
      fontWeight: '700',
    },
    h5: {
      fontSize: '18px',
      fontWeight: '700',
    },
    h6: {
      fontSize: '14px',
      fontWeight: '700',
    },
  },
});


const haierTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: haierBrandColorSecondary,
        borderRadius: '40px',
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
          backgroundColor: haierBrandColor,
        }
      }
    },
  },
  palette: {
    primary: {
      main: haierBrandColor,
    },
    secondary: {
      main: haierBrandColorSecondary,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: haierFontFamily,
    h1: {
      fontSize: "40px",
      fontWight: "700",
    },
    h2: {
      fontSize: "32px",
      fontWeight: "700",
    },
    h3: {
      fontSize: "28x",
      fontWeight: "700",
    },
    h4: {
      fontSize: "22px",
      fontWeight: "700",
    },
    h5: {
      fontSize: "18px",
      fontWeight: "700",
    },
    h6: {
      fontSize: "14px",
      fontWeight: "700",
    },
  },
});

const brandTheme = {
  candy: candyTheme,
  hoover: hooverTheme,
  haier: haierTheme,
};

export default brandTheme[process.env.REACT_APP_BRAND];
