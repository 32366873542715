import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Page404 from './shared/Page404/index';
import Loader from '../components/Loader/index';

const { REACT_APP_STATIC_BUCKET_URL } = process.env;

class StaticPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({ page: PropTypes.string }),
    }).isRequired,
  };

  state = {
    isLoading: true,
  };

  async componentDidMount() {
    await this.getStaticContent();
  }

  async componentDidUpdate(prevProps) {
    const prevPage = prevProps.match.params.page;
    const nextPage = this.props.match.params.page;

    if (nextPage !== prevPage) {
      await this.getStaticContent();
    }
  }

  async getStaticContent() {
    let { page } = this.props.match.params;
    if (typeof page === 'undefined') {
      page = 'homepage';
    }
    try {
      const result = await axios.get(
        `${REACT_APP_STATIC_BUCKET_URL}/${page}.html`,
      );
      const [, body] = /<body.*?>([\s\S]*)<\/body>/.exec(result.data);
      this.setState({ body });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { body, isLoading } = this.state;
    if (isLoading)
      return (
        <Loader />
      );
    return body ? (
      <div className="static" dangerouslySetInnerHTML={{ __html: body }} />
    ) : (
      <Page404 />
    );
  }
}

export default StaticPage;
