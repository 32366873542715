import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles/index';
import ToolBar from '../components/Toolbar';
import FooterComponent from '../components/Footer';

import Auth from './Auth';
import StaticPage from './StaticPage';
import Product from './Product';
import ApplicationForm from './ApplicationForm';
import Applications from './Applications';
import { generalStyles } from '../style/general';

const App = ({ classes }) => (
  <React.Fragment>
    <CssBaseline />
    <ToolBar />
    <main>
      <div className={classNames(classes.layout)}>
        <Switch>
          <Route path="/auth" component={Auth} />
          <Route path="/static/:page" component={StaticPage} />
          <Route path="/produkt/:id" component={Product} />
          <Route path="/produkt" component={Product} />
          <Route path="/rejestracja/lista" component={Applications} />
          <Route
            path="/rejestracja/:promotion_uuid"
            component={ApplicationForm}
          />
          <Route component={StaticPage} />
        </Switch>
      </div>
    </main>
    <FooterComponent />
  </React.Fragment>
);

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(generalStyles)(App);
