import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import brandTheme from '../../../style/themes/brandTheme';
import { StyledCardBlock, StyledCardWrapper } from './style/card';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing.unit * 8,
    padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 4}px ${theme
      .spacing.unit * 4}px`,
  },
});

const Card = ({ classes, width, children }) => (
  <StyledCardWrapper style={{ width }}>
    <StyledCardBlock theme={brandTheme} className={classes.paper}>
      {children}
    </StyledCardBlock>
  </StyledCardWrapper>
);

Card.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Card.defaultProps = {
  width: 'auto',
};

export default withStyles(styles)(Card);
