import { css } from 'styled-components';

const sizes = {
  desktop: 1024,
  tabletBig: 720,
  tablet: 500,
  phone: 320,
};

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});
