import styled from 'styled-components';

export const StyledCardWrapper = styled.div`
  min-height: 100px;
  margin-top: 80px;
  text-align: center;
`;

export const StyledLink = styled.a`
  line-height: 50px;
  text-decoration: none;
  color: ${(props) => props.theme.primaryBrandColor};

  &:hover {
    color: ${(props) => props.theme.greyText};
  }
`;
